import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-menu-logo',
  standalone: true,
  templateUrl: './menu-logo.component.html',
  styleUrls: ['./menu-logo.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, MatIconModule]
})
export class MenuLogoComponent {
  @Input() hideMenu: boolean;
  @Input() hideLogo: boolean;
  @Input() isInvertColor: boolean;
  @Output() toggleSidenav = new EventEmitter<void>();
  public locationOrigin: string;

  constructor() {
    this.locationOrigin = location.origin;
  }
}
